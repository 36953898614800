import config from "./../config";

class UtilImages { 
  bufferToUrl = (buffer) => {
    const mimeType = this.getImageType(buffer)

    const blob = new Blob([new Uint8Array(buffer.data)], { type: mimeType });
    return URL.createObjectURL(blob);
  };

  binaryToBase64 = (binary) => {
    const base64 = btoa(
      new Uint8Array(binary).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        '',
      ),
    );
    return `data:image/png;base64,${base64}`;
  };

  getImageType = (buffer) => {
    const uint = new Uint8Array(buffer);
    let bytes = [];
    uint.forEach((byte) => {
        bytes.push(byte.toString(16));
    });
    const hex = bytes.join('').toUpperCase();

    if (hex.startsWith('89504E47')) { 
        return 'image/png';
    } else if (hex.startsWith('FFD8')) {
        return 'image/jpeg';
    }
  }

  getImage = (avatarUrl) => {
    if(avatarUrl && avatarUrl.length > 150) return avatarUrl;

    const cacheKey = `avatar_cache_${avatarUrl}`;
    const cachedItem = JSON.parse(localStorage.getItem(cacheKey));
    const now = Date.now();

    if (cachedItem && now - cachedItem.timestamp < 20000) {
      return cachedItem.data;
    }

    const image = `${config.API_URL}${avatarUrl}`;

    localStorage.setItem(
      cacheKey,
      JSON.stringify({
        timestamp: now,
        data: image,
        ttl: 20000
      })
    );

    return image;
  };
  
  
}

const UtilImage = new UtilImages();
export default UtilImage;