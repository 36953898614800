import React from 'react';
import Routes from './routes/';
//Import Scss
import "./assets/scss/themes.scss";
import "./assets/css/styles.css";
import "react-contexify/dist/ReactContexify.css"
import "react-medium-image-zoom/dist/styles.css";
import { LoadContext } from './contexts/LoadContext';

function App() {
  return (
    <LoadContext>
      <Routes />
    </LoadContext>
  );
}

export default App;
