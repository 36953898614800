import BaseResourceService from "src/services/BaseResourceService";

class ConexaoService extends BaseResourceService {
  endpoint = "conexao";

  getAllConexoesByUser = async (isAtivo = true, setor = "comercial", userId) => {
    let params = [];
    params.push({ chave: 'isAtivo', valor: isAtivo });
    params.push({ chave: 'setor', valor: setor });
    params.push({ chave: 'userOwner', valor: userId });

    return this.getAll(this.endpoint, params);
  };

  getAllConexoes = async (
    isAtivo = true, 
    setor = "suporte", 
    isBanido = null, 
    isDesbanidos = null,
    numeroId = null,
    isAnalise = null,
    filter) => {
    let params = [];
    params.push({ chave: 'isAtivo', valor: isAtivo });
    // params.push({ chave: 'isConectado', valor: isAtivo });
    params.push({ chave: 'setor', valor: setor });
    if (isBanido != null && isBanido)
      params.push({ chave: 'isBanido', valor: isBanido });
    if (isDesbanidos != null && isDesbanidos)
      params.push({ chave: 'isDesbanidos', valor: isDesbanidos });
    if (isAnalise != null && isAnalise)
      params.push({ chave: 'isAnalise', valor: isAnalise });
    if (numeroId)
      params.push({ chave: '_id', valor: numeroId });
    // if (filter){
    //   params.push({ chave: 'nome', valor: numeroId });
    //   params.push({ chave: 'numero', valor: numeroId });
    // }

    return this.getAll(this.endpoint, params);
  };

  startWorker = async (conexaoId) => {
    let params = [];
    params.push({ chave: 'conexaoId', valor: conexaoId });
    return this.getAll(`${this.endpoint}/startworker`, params);
  };

  destroyWorker = async (conexaoId) => {
    let params = [];
    params.push({ chave: 'conexaoId', valor: conexaoId });
    return this.getAll(`${this.endpoint}/destroy`, params);
  };

  destroyConectados = async () => {
    return this.getAll(`${this.endpoint}/destroyConectados`);
  };

  atualizarMsgs = async (conexaoId) => {
    let params = [];
    params.push({ chave: 'conexaoId', valor: conexaoId });
    return this.getAll(`${this.endpoint}/atualizar`, params);
  };

  getQrCode = async (conexaoId) => {
    let params = [];
    params.push({ chave: 'conexaoId', valor: conexaoId });
    return this.getAll(`${this.endpoint}/qrcode`, params);
  };

  salvarConexao(conexao) {
    return new Promise((resolve, reject) => {
      this.saveOrUpdate(`/${this.endpoint}`, conexao)
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  }

  informarBanimento(conexao) {
    return new Promise((resolve, reject) => {
      this.post(`/${this.endpoint}/informarBanimento`, conexao)
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  }

  getByCodigo = async (id) => {
    return this.findModelo(this.endpoint, id);
  };

}

const sevice = new ConexaoService();
export default sevice;
