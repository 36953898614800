import moment from 'moment';
import { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import locale from 'date-fns/locale/pt';
import { addDays, subDays } from 'date-fns';

export default function CalendarFull({ currentDate, setCurrentDate }) {
  const initialState = {
    startDate: subDays(new Date(), 7),
    endDate: addDays(new Date(), 1),
    key: 'selection',
  };

  const [calendarConfig, setCalendarConfig] = useState(initialState);

  const handleChange = (ranges) => {
    const { selection } = ranges;
    setCalendarConfig({ ...calendarConfig, ...selection });
    setCurrentDate({
      startDate: moment(selection.startDate), 
      endDate: moment(selection.endDate)
    });
  };

  useEffect(() => {
    setCalendarConfig({
      startDate: new Date(currentDate.startDate),
      endDate: new Date(currentDate.endDate),
      key: 'selection'
    });
  }, [currentDate])

  return (
    <DateRange
      locale={locale}
      editableDateInputs={true}
      className='w-100 '
      onChange={handleChange}
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      ranges={[calendarConfig]}
      rangeColors={['#7269ef']}
      direction="horizontal"
    />
  );
}
