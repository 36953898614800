import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import moment from "moment";
export function DynamicYear({currentDate, setCurrentDate}) {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <IconButton onClick={() => {
        setCurrentDate({
          startDate: moment(currentDate.startDate).subtract(1, 'years'),
          endDate: moment(currentDate.endDate).subtract(1, 'years')
        });
      }}>
        <KeyboardArrowLeftIcon style={{color: '#ffffff'}}/>
      </IconButton>
      <h4 style={{color: '#ffffff'}}>{moment(currentDate.endDate).year()}</h4>
      <IconButton onClick={() => {
        setCurrentDate({
          startDate: moment(currentDate.startDate).add(1, 'years'),
          endDate: moment(currentDate.endDate).add(1, 'years')
        });
      }}>
        <KeyboardArrowRightIcon style={{color: '#ffffff'}} />
      </IconButton>
    </div>
  )
}