import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { Row, Col } from "reactstrap";

class WarningToast extends Component {

    render () {
        return (
            <React.Fragment>
              <Row style={{paddingLeft: 10}}>
                <i style={{paddingTop: 10, fontSize: 20}} className="fas fa-exclamation-triangle"></i>
                  <Col style={{paddingLeft: 10}}>
                    {this.props.texto}
                  </Col>
              </Row>
            </React.Fragment>
        );
    }
}

const styles = {
};

export default withStyles(styles)(WarningToast);