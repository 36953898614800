import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import ReactAudioPlayer from 'react-audio-player';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogAudioPlayer({ open, setOpen, handleEnded, value }) {

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <div style={{ background: 'transparent' }}>
                {
                    value &&
                    <ReactAudioPlayer
                        onEnded={handleEnded}
                        autoPlay
                        title="audio.ogg"
                        src={`data:${value.media.mimetype};base64,${new Buffer(
                            value.media.data
                        ).toString("base64")}`}
                        controls
                    />
                }
            </div>
        </Dialog>
    );
}
