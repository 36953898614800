import React, { useState } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import { setActiveTab } from "../../redux/actions";
import DropdownComponent from "./DropdownComponent";
import ModalCentralAgendamento from "../../pages/Comercial/AgendamentoResposta"
import { useLoadContext } from "src/contexts/LoadContext";

import DropdownGzap from "./DropdownGzap";

function LeftSidebarMenu(props) {
  const { user, openInspectUser } = useLoadContext();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenMobile, setDropdownOpenMobile] = useState(false);
  const [openModalAgendamento, setOpenModalAgendamento] = useState(false);
  const [openNesteModal, setNestedModal] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);
  const toggleAgendamento = () => setOpenModalAgendamento(!openModalAgendamento);
  const toggleMobile = () => setDropdownOpenMobile(!dropdownOpenMobile);
  const [dropdownToolsOpenMobile, setDropdownToolsOpenMobile] = useState(false);
  const toggleMobileTools = () =>
    setDropdownToolsOpenMobile(!dropdownToolsOpenMobile);

  const toggleTab = (tab) => {
    props.setActiveTab(tab);
  };


  const [dropdownOpenSetor, setDropdownOpenSetor] = useState(false);

  const toggleSetor = () => setDropdownOpenSetor(!dropdownOpenSetor);

  const toggleSetorTab = (tab) => {
    props.setActiveTab(tab);
  };

  return (
    <React.Fragment>
      <div className="side-menu flex-lg-column me-lg-1">
        <div className="flex-lg-column d-none d-lg-block" onDoubleClick={() => {
          openInspectUser(null)
        }}>
          <Nav className="side-menu-nav justify-content-center">
            <DropdownGzap
              id="gzap"
              isDropOpen={dropdownOpenSetor}
              toggleDrop={toggleSetor}
              toggleTab={toggleSetorTab}
              dropClassName="nav-item btn-group dropup profile-user-dropdown"
            />
          </Nav>
        </div>

        {user && ["suporte", "dev", "financeiro"].includes(user.setor) && (
          <div className="flex-lg-column my-auto">
            <Nav
              pills
              className="side-menu-nav justify-content-center"
              role="tablist"
            >
              {user && user.isAdmin && (
                <React.Fragment>
                  <NavItem id="dashboard">
                    <NavLink
                      id="pills-groups-tab"
                      className={classnames({
                        active: props.history.location.pathname.startsWith(
                          "/dashboard-atendimento"
                        ),
                      })}
                      onClick={() => {
                        toggleTab("dashboards");
                        props.history.push("/dashboard-atendimento");
                      }}
                    >
                      <i className="ri-bar-chart-2-fill"></i>
                    </NavLink>
                  </NavItem>
                </React.Fragment>
              )}

              <NavItem id="Chats">
                <NavLink
                  id="pills-chat-tab"
                  className={classnames({
                    active:
                      props.history.location.pathname.startsWith("/chats"),
                  })}
                  onClick={() => {
                    toggleTab("chat");
                    props.history.push("/chats");
                  }}
                >
                  <i className="ri-message-3-line"></i>
                </NavLink>
              </NavItem>
              <UncontrolledTooltip target="Chats" placement="top">
                Chats
              </UncontrolledTooltip>

              <NavItem id="Atendimentos">
                <NavLink
                  id="pills-chat-tab"
                  className={classnames({
                    active:
                      props.history.location.pathname.startsWith(
                        "/atendimentos"
                      ),
                  })}
                  onClick={() => {
                    toggleTab("chat");
                    props.history.push("/atendimentos");
                  }}
                >
                  <i className="ri-inbox-archive-line"></i>
                  {/* <i className="ri-whatsapp-line"></i> */}
                </NavLink>
              </NavItem>
              <UncontrolledTooltip target="Atendimentos" placement="top">
                Atendimentos
              </UncontrolledTooltip>
              <NavItem id="sobreaviso">
                <NavLink
                  id="pills-chat-tab"
                  className={classnames({
                    active:
                      props.history.location.pathname.startsWith("/sobreaviso"),
                  })}
                  onClick={() => {
                    toggleTab("sobreaviso");
                    props.history.push("/sobreaviso");
                  }}
                >
                  <i className="ri-hospital-line"></i>
                </NavLink>
              </NavItem>
              <UncontrolledTooltip target="sobreaviso" placement="top">
                Sobreaviso
              </UncontrolledTooltip>

              {user && user.isAdmin && (
                <React.Fragment>
                  <UncontrolledTooltip target="tools" placement="top">
                    Configurações de Cadastro
                  </UncontrolledTooltip>

                  <Dropdown
                    nav
                    id="tools"
                    isOpen={dropdownToolsOpenMobile}
                    toggle={toggleMobileTools}
                  >
                    <DropdownToggle
                      nav
                      className={classnames({
                        active:
                          props.history.location.pathname.startsWith(
                            "/cad-"
                          ),
                      })}
                    >
                      <i className="ri-tools-line"></i>
                    </DropdownToggle>
                    <DropdownMenu
                      className="dropdown-menu-end"
                      onClick={() => {
                        toggleTab("cad");
                      }}
                    >
                      <DropdownItem
                        id="suporte-mensagens"
                        style={{ padding: 10 }}
                        onClick={() => {
                          toggleTab("cad-mensagens");
                          props.history.push("/cad-mensagens");
                        }}
                      >
                        Msg. Rapida{" "}
                        <i className="ri-smartphone-line float-end text-muted"></i>
                      </DropdownItem>
                      <DropdownItem
                        id="suporte-conexoes"
                        style={{ padding: 10 }}
                        onClick={() => {
                          toggleTab("cad-conexoes");
                          props.history.push("/cad-conexoes");
                        }}
                      >
                        Conexões{" "}
                        <i className="ri-chat-settings-line float-end text-muted"></i>
                      </DropdownItem>
                      <DropdownItem
                        id="suporte-categorias"
                        style={{ padding: 10 }}
                        onClick={() => {
                          toggleTab("cad-categorias");
                          props.history.push("/cad-categorias");
                        }}
                      >
                        Categorias{" "}
                        <i className="ri-grid-fill float-end text-muted"></i>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        id="suporte-usuarios"
                        style={{ padding: 10 }}
                        onClick={() => {
                          toggleTab("cad-usuarios");
                          props.history.push("/cad-usuarios");
                        }}
                      >
                        Usuários{" "}
                        <i className="ri-group-line float-end text-muted"></i>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </React.Fragment>
              )}

              <DropdownComponent
                id="usuario"
                dropClassName="profile-user-dropdown d-inline-block d-lg-none"
                isDropOpen={dropdownOpenMobile}
                toggleDrop={toggleMobile}
                toggleTab={toggleTab}
                history={props.history}
              />
            </Nav>
          </div>
        )}

        {user && ["comercial"].includes(user.setor) && (
          <>
            <ModalCentralAgendamento
              openModal={openModalAgendamento}
              setOpenModal={setOpenModalAgendamento}
              openNesteModal={openNesteModal}
              setNestedModal={setNestedModal}
            />
            <div className="flex-lg-column my-auto">
              <Nav
                pills
                className="side-menu-nav justify-content-center"
                role="tablist"
              >
                <NavItem id="agendamentos_resposta">
                  <NavLink
                    id="pills-chat-tab"
                    className={classnames({
                      active:
                        openModalAgendamento
                    })}
                    onClick={() => toggleAgendamento() }
                  >
                    <i className="ri-calendar-event-line"></i>
                  </NavLink>
                </NavItem>
                <UncontrolledTooltip target="agendamentos_resposta" placement="top">
                  Central de Agendamentos
                </UncontrolledTooltip>
                <NavItem id="Chats">
                  <NavLink
                    id="pills-chat-tab"
                    className={classnames({
                      active:
                        props.history.location.pathname.startsWith(
                          "/chats-comercial"
                        ),
                    })}
                    onClick={() => {
                      toggleTab("chats-comercial");
                      props.history.push("/chats-comercial");
                    }}
                  >
                    <i className="ri-message-3-line"></i>
                  </NavLink>
                </NavItem>
                <UncontrolledTooltip target="Chats" placement="top">
                  Chats
                </UncontrolledTooltip>

                <NavItem id="Atendimentos">
                  <NavLink
                    id="pills-chat-tab"
                    className={classnames({
                      active:
                        props.history.location.pathname.startsWith(
                          "/atendimentos"
                        ),
                    })}
                    onClick={() => {
                      toggleTab("chat");
                      props.history.push("/atendimentos");
                    }}
                  >
                    <i className="ri-inbox-archive-line"></i>
                    {/* <i className="ri-whatsapp-line"></i> */}
                  </NavLink>
                </NavItem>
                <UncontrolledTooltip target="Atendimentos" placement="top">
                  Atendimentos
                </UncontrolledTooltip>

                {user && user.isAdmin && (
                  <React.Fragment>
                    <UncontrolledTooltip target="tools" placement="top">
                      Configurações de Cadastro
                    </UncontrolledTooltip>

                    <Dropdown
                      id="tools"
                      nav
                      isOpen={dropdownToolsOpenMobile}
                      toggle={toggleMobileTools}
                    >
                      <DropdownToggle
                        nav
                        className={classnames({
                          active:
                            props.history.location.pathname.startsWith(
                              "/cad-"
                            ),
                        })}
                      >
                        <i className="ri-tools-line"></i>
                      </DropdownToggle>
                      <DropdownMenu
                        className="dropdown-menu-end"
                        onClick={() => {
                          toggleTab("comercial");
                        }}
                      >
                        <DropdownItem
                          id="suporte-mensagens"
                          style={{ padding: 10 }}
                          onClick={() => {
                            toggleTab("cad-mensagens");
                            props.history.push("/cad-mensagens");
                          }}
                        >
                          Msg. Rapida{" "}
                          <i className="ri-smartphone-line float-end text-muted"></i>
                        </DropdownItem>
                        <DropdownItem
                          id="comercial-email"
                          style={{ padding: 10 }}
                          onClick={() => {
                            toggleTab("cad-email");
                            props.history.push("/cad-email");
                          }}
                        >
                          E-mail{" "}
                          <i className="ri-mail-line float-end text-muted"></i>
                        </DropdownItem>
                        <DropdownItem
                          id="comercial-conexoes"
                          style={{ padding: 10 }}
                          onClick={() => {
                            toggleTab("cad-conexoes");
                            props.history.push("/cad-conexoes");
                          }}
                        >
                          Conexões{" "}
                          <i className="ri-chat-settings-line float-end text-muted"></i>
                        </DropdownItem>
                        <DropdownItem
                          id="suporte-categorias"
                          style={{ padding: 10 }}
                          onClick={() => {
                            toggleTab("cad-categorias");
                            props.history.push("/cad-categorias");
                          }}
                        >
                          Categorias{" "}
                          <i className="ri-grid-fill float-end text-muted"></i>
                        </DropdownItem>
                        <DropdownItem
                          id="suporte-categorias"
                          style={{ padding: 10 }}
                          onClick={() => {
                            toggleTab("disparos");
                            props.history.push("/disparos");
                          }}
                        >
                          Disparos{" "}
                          <i className="ri-send-plane-fill float-end text-muted"></i>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem
                          id="comercial-usuarios"
                          style={{ padding: 10 }}
                          onClick={() => {
                            toggleTab("cad-usuarios");
                            props.history.push("/cad-usuarios");
                          }}
                        >
                          Usuários{" "}
                          <i className="ri-group-line float-end text-muted"></i>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </React.Fragment>
                )}

                <DropdownComponent
                  isDropOpen={dropdownOpenMobile}
                  dropClassName="profile-user-dropdown d-inline-block d-lg-none"
                  toggleDrop={toggleMobile}
                  user={user}
                />
              </Nav>
            </div>
          </>
        )}

        <div className="flex-lg-column d-none d-lg-block" onDoubleClick={() => {
          openInspectUser(null)
        }}>
          <Nav className="side-menu-nav justify-content-center">
            <DropdownComponent
              id="usuario"
              dropClassName="nav-item btn-group dropup profile-user-dropdown"
              isDropOpen={dropdownOpen}
              toggleDrop={toggle}
              toggleTab={toggleTab}
              history={props.history}
            />
          </Nav>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};

export default connect(mapStatetoProps, {
  setActiveTab,
})(LeftSidebarMenu);
