import React from "react";
import { Input, Label, InputGroup } from "reactstrap";

function CustomInput(props) {
  return (
    <React.Fragment>
      {
        props.label &&
        <Label className="form-label">{props.label}</Label>
      }
      <InputGroup className="input-group bg-soft-light rounded-3 mb-3">
        {props.icon && (
          <span className="input-group-text text-muted">{props.icon}</span>
        )}
        <Input
          autoComplete="nope"
          type={props.type ? props.type : "text"}
          id={props.id}
          name={props.name}
          className="form-control form-control-lg bg-soft-light border-light"
          placeholder={props.placeholder}
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={props.value ? props.value : ""}
          invalid={props.touched && props.errors ? true : false}
          disabled={props.disabled ? props.disabled : false}
          bsSize="sm"
        />
        {/* {props.touched && props.errors ? (
          <FormFeedback
            style={{
              position: "absolute",
              paddingLeft: props.icon ? 64 : 10,
              paddingTop: 23,
              fontSize: 12,
            }}
            type="invalid"
          >
            {props.errors}
          </FormFeedback>
        ) : null} */}
      </InputGroup>
    </React.Fragment>
  );
}

export default CustomInput;
