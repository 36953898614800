import React, { createContext, useState, useContext, useEffect } from "react";
import utilImages from 'src/utils/util-images';
import Loading from "../components/Loading";
import EscalaSobreavisoService from 'src/services/sobreaviso/EscalaSobreaviso.service';
import DialogAudioPlayer from "src/components/DialogAudioPlayer";
import jwtService from "src/services/jwt.service";
import conversaService from "src/services/conversa/conversa.service";
import usuarioService from "src/services/usuario/usuario.service";
import ModalImage from "src/components/modal/ModalImage";
import mensagemService from "src/services/mensagem/mensagem.service";

const Context = createContext();

export const LoadContext = ({ children }) => {
    const [user, setUser] = useState();
    const [inspectUser, setInspectUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [plantonista, setplantonista] = useState();
    const [plantaoAtivado, setPlantaoAtivado] = useState(false);
    const [msgInternaOculta, setMsgInternaOculta] = useState(false);
    const [usuarios, setusuarios] = useState([])

    const [activeChatImg, setactiveChatImg] = useState();
    const [img, setImg] = useState();
    const [tipoImage, settipoImage] = useState("image/png");

    const [showmodal, setshowmodal] = useState();
    const [showbutton, setshowbutton] = useState(false);

    async function getUsuarios() {
        await usuarioService.getAllUsuarios().then(e => {
            setusuarios(e);
            return e;
        }).catch(e => console.log(e));
    }

    function getUsuarioById(id) {
        let us = id === user.id ? user : null;
        if (!us)
            us = usuarios.find(e => e.id === id);
        if (us)
            return us.name
        return '';
    }

    function getAvatarUser(avatar) {
        if (!avatar) return;

        avatar = utilImages.getImage(avatar.avatarUrl);
        return avatar
    }

    async function handleSetor(setor) {
        if (user.isMaster) {
            const newUser = { ...user, setor };
            setUser(newUser);
            localStorage.setItem('authUser', JSON.stringify(newUser));
            jwtService.updateInterceptor();
        }
    }

    const buscarPlantonista = async () => {
        let u = null;
        await EscalaSobreavisoService.getAllEscalaSobreavisoNmes().then(e => {
            if (e)
                if (e.length > 0) {
                    u = e[0].usuario
                    setplantonista(u)
                }
        }).catch(() => { }).finally(() => { return u });
    }

    useEffect(() => {
        const fetchUserData = () => {
            try {
                const u = JSON.parse(localStorage.getItem("authUser"));

                const avatarUrl = getAvatarUser(u)
                setUser({...u, avatarUrl });
                if (u) {
                    if (userLogado()) {
                        buscarPlantonista();
                        getUsuarios();
                    }
                }
    
            } catch (e) {
                console.error(e);
            }
        };

        fetchUserData();
    }, []);

    useEffect(() => {
        if (user) {
            buscarPlantonista()
            getUsuarios()
        }
    }, [user])

    const userLogado = () => {
        const u = JSON.parse(localStorage.getItem("authUser"));
        if (u.inspectUser)
            setInspectUser(u.inspectUser);
        setUser(u);
        return u;
    }

    const isPlantao = () => {
        if (plantonista)
            if (plantonista.id === JSON.parse(localStorage.getItem("authUser")).id) {
                return true
            }
        return false;
    }

    const [showAudio, setAudio] = useState(false)
    const [msgAudio, setMsgAudio] = useState('')


    function handleEnded() {
        setAudio(false)
        setMsgAudio()
    }

    function handleRender(props) {
        setAudio(true)
        setMsgAudio(props)
    }

    async function atendimentosInatividade() {
        if (user.setor === 'suporte') {
            await conversaService.conversasInativasNotificar();
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (user) {
                atendimentosInatividade();
            }
        }, 1000 * 60 * 5) //3 min in milliseconds
        return () => clearInterval(intervalId)

        // eslint-disable-next-line
    }, [user])

    async function openInspectUser(_user) {
        if (_user === null)
            _user = user.id;
        await usuarioService.inspectUser({ id: user.id, inspectUser: _user }).then(e => {
            setInspectUser(_user)
            localStorage.setItem("authUser", JSON.stringify({ ...user, inspectUser: _user }))
        }).catch(e => { })
    }

    async function sendImage() {
        if (img && activeChatImg) {
            console.log({ activeChatImg })
            const fileArray = img.split("base64,");
            if (activeChatImg.isAtendimento) {
                if (img.includes("image"))
                    await mensagemService
                        .enviarImagemAtendimento(
                            fileArray[1],
                            activeChatImg.chat._id,
                            activeChatImg._id,
                            tipoImage,
                            false
                        )
                        .finally(() => {
                            setImg()
                            setshowmodal(false)
                        });
                else
                    await mensagemService.enviarArquivoAtendimento(
                        fileArray[1],
                        activeChatImg.chat._id,
                        activeChatImg._id,
                        tipoImage,
                        activeChatImg.chat._id,
                    )
                        .finally(() => {
                            setImg()
                            setshowmodal(false)
                        });
            } else {
                if (img.includes("image"))
                    await mensagemService
                        .enviarImagem(
                            fileArray[1],
                            activeChatImg.chat._id,
                            activeChatImg._id,
                            tipoImage,
                            false
                        )
                        .finally(() => {
                            setImg()
                            setshowmodal(false)
                        });
                else
                    await mensagemService.enviarArquivo(
                        fileArray[1],
                        activeChatImg.chat._id,
                        activeChatImg._id,
                        tipoImage,
                        activeChatImg.chat._id,
                    )
                        .finally(() => {
                            setImg()
                            setshowmodal(false)
                        });
            }
        }
        setshowbutton(false)
    }

    return (
        <Context.Provider
            value={{
                loading, setLoading,
                plantonista, setplantonista,
                isPlantao, userLogado,
                plantaoAtivado, setPlantaoAtivado,
                buscarPlantonista,
                msgInternaOculta, setMsgInternaOculta,
                user, setUser,
                handleRender,
                inspectUser, setInspectUser,
                handleSetor,
                openInspectUser,
                getUsuarioById,
                setImg, settipoImage,
                showmodal, setshowmodal,
                setshowbutton,
                sendImage, setactiveChatImg
            }}>
            <ModalImage image={img} isOpen={showmodal} setOpen={setshowmodal} handleSubmit={sendImage} tipo={tipoImage} showbutton={showbutton} setshowbutton={setshowbutton} />
            <DialogAudioPlayer setOpen={setAudio} open={showAudio} value={msgAudio} handleEnded={handleEnded} />
            <Loading loading={loading} />
            {children}
        </Context.Provider>
    );
};

export const useLoadContext = () => {
    const context = useContext(Context);
    return context;
};