import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Button } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, setOpen, handleSuccess, title, children, textSuccess = "Concluir", textClose = "Cancelar" }) {

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <div style={{ background: '#262e35' }}>
                <DialogTitle color="#A6B0CF">{title}</DialogTitle>
                {
                    children &&
                    <React.Fragment>
                        <div className="border-bottom" />
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description" color="#A6B0CF">
                                {
                                    children
                                }
                            </DialogContentText>
                        </DialogContent>
                    </React.Fragment>
                }
                <div className="border-bottom" />
                <DialogActions>
                    <Button
                        color="primary"
                        style={{ paddingLeft: 5, minWidth: 25 }}
                        type="submit"
                        onClick={handleClose}
                    >
                        <i className="ri-close-fill"></i>
                        {textClose}
                    </Button>
                    <Button
                        color="primary"
                        style={{ paddingLeft: 5, minWidth: 25 }}
                        type="submit"
                        onClick={handleSuccess}
                    >
                        <i className="ri-check-double-fill me-1"></i>
                        {textSuccess}
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}
