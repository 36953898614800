import React, {useState, useEffect} from 'react';
import { Avatar } from "@material-ui/core";
import {
    Row,
    Button,
    Modal,
    ModalBody,
} from "reactstrap";

const ModalImage = ({ isOpen, setOpen, handleSubmit, image, tipo, showbutton, setshowbutton }) =>{
    const [botaoBloqueado, setBotaoBloqueado] = useState(false);
    const handleButtonClicked = () =>{
        handleSubmit();
        setBotaoBloqueado(true);
    };

    const resetButton = ()=>{
        setBotaoBloqueado(false);
    };

    useEffect(() =>{
        if(!isOpen){
            resetButton();
        }
    },[isOpen]);

    return(
    <Modal
        isOpen={isOpen}
        size="lg"
        toggle={() => {
            setshowbutton(false)
            setOpen(!isOpen)
        }}
    >
        <ModalBody className="" style={{ paddingLeft: 20 }}>
            <div
                className="dot_remove"
                dir="ltr"
                style={{ userSelect: "none" }}
            >
                <Row>
                    {
                        image ?
                            tipo === 'image/png' ?
                                <Avatar
                                    style={{
                                        height: 500, width: '100%',
                                        resize: 'inherit',
                                        display: 'inline-table'
                                    }}
                                    variant="square"
                                    src={image}
                                >
                                </Avatar>
                                : ("" + image).includes('video') ?
                                    <video
                                        autoPlay
                                        style={{
                                            height: 500, width: '100%',
                                            resize: 'inherit'
                                        }}
                                        src={image}
                                    >
                                    </video>
                                    :
                                    <></>
                            :
                            <></>
                    }
                    {
                        handleSubmit && showbutton && (
                        <>
                            <br />
                            <Button
                                color="primary"
                                style={{ paddingLeft: 5, minWidth: 25 }}
                                onClick={handleButtonClicked}
                                disabled={botaoBloqueado}
                            >
                                <i className="ri-check-double-fill me-1"></i>
                                Enviar
                            </Button>
                        </>
                    )}
                    {/* </Form> */}
                </Row>
            </div>
        </ModalBody>
    </Modal >
    );
};

export default ModalImage