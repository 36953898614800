
import BaseResourceService from "src/services/BaseResourceService";
import moment from 'moment'

class EscalaSobreavisoService extends BaseResourceService {
    endpoint = "escalaSobreaviso";

    getAllEscalaSobreaviso = async (mes, ano = null) => {
        let params = [];
        params.push({ chave: 'mes', valor: mes });
        params.push({ chave: 'ano', valor: ano });
        return this.getAll(this.endpoint + "/", params);
    };

    getAllEscalaSobreavisoNmes = async () => {
        let params = [];
        params.push({ chave: 'atendenteData', valor: moment().set('h', 0).set('m', 0).format('YYYY-MM-DD HH:mm') });

        return this.getAll(this.endpoint + "/", params);
    };
    getSobreavisoUsuarioMes = async (usuario, dataInicio, dataFim) => {
        let params = [];
        params.push({ chave: 'userOwner', valor: usuario });
        params.push({ chave: 'dataInicio', valor: dataInicio });
        params.push({ chave: 'dataFim', valor: dataFim });
        return this.getAll(this.endpoint + "/sobreavisoUsuarioMes", params);
    };

    salvar(categorias) {
        return new Promise((resolve, reject) => {
            this.saveOrUpdate(`/${this.endpoint}`, categorias)
                .then((response) => {
                    if (response && response.data) {
                        resolve(response.data);
                    } else {
                        this.tratarErrosToast(response);
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    this.tratarErrosToast(error.response);
                    reject(error.response);
                });
        });
    }
    update(categorias) {
        return new Promise((resolve, reject) => {
            this.saveOrUpdate(`/${this.endpoint}`, categorias)
                .then((response) => {
                    if (response && response.data) {
                        resolve(response.data);
                    } else {
                        this.tratarErrosToast(response);
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    this.tratarErrosToast(error.response);
                    reject(error.response);
                });
        });
    }

    getByCodigo = async (id) => {
        return this.findModelo(this.endpoint, id);
    };

    remove = async (id) => {

        return this.delete(this.endpoint + '/?_id=' + id)
    };

}

const sevice = new EscalaSobreavisoService();
export default sevice;
