import React from "react";
import { Redirect } from "react-router-dom";

//User and Config Routes
const Profile = React.lazy(() => import("../pages/Profile/index"));
const Config = React.lazy(() => import("../pages/Config/index"));

// Suporte Routes
const Usuarios = React.lazy(() => import("../pages/Usuarios/index"));
const Conexoes = React.lazy(() => import("../pages/Suporte/Conexao/index"));
const Chats = React.lazy(() => import("../pages/Suporte/Chats/index"));
const Atendimentos = React.lazy(() =>
  import("../pages/Suporte/Atendimentos/index")
);
const Categoria = React.lazy(() => import("../pages/Categoria"));
const MensagemRapida = React.lazy(() =>
  import("../pages/MensagemRapida")
);
const DashboardAtendimento = React.lazy(() =>
  import("../pages/Suporte/DashboardAtendimento")
);
const Sobreaviso = React.lazy(() =>
  import("../pages/Suporte/Sobreaviso/index")
);
const Lancamentos = React.lazy(() => import("../pages/Suporte/Lancamentos/index"));

//Comercial Routes
const ConexoesComercial = React.lazy(() => import("../pages/Comercial/Conexao/index"));
const Email = React.lazy(() => import("src/pages/Comercial/Email"));
const ChatsComercial = React.lazy(() => import("../pages/Comercial/Chats/index"));
const DisparosComercial = React.lazy(() => import("../pages/Comercial/Disparo/index"));
const AgendamentoResposta = React.lazy(() => import("../pages/Comercial/AgendamentoResposta/index"));

// auth
const Login = React.lazy(() => import("../pages/Auth/Login"));

// declare all routes
const authProtectedSuporteRoutes = [
  { path: "/cad-usuarios", component: Usuarios, adminRoute: true },
  { path: "/cad-conexoes", component: Conexoes, adminRoute: true },
  { path: "/cad-categorias", component: Categoria },
  { path: "/cad-mensagens", component: MensagemRapida, adminRoute: true },
  { path: "/chats", component: Chats },
  { path: "/atendimentos", component: Atendimentos },
  { path: "/profile", component: Profile },
  { path: "/config", component: Config, adminRoute: true },
  {
    path: "/dashboard-atendimento",
    component: DashboardAtendimento,
    adminRoute: true,
  },
  { path: "/sobreaviso", component: Sobreaviso },

  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/chats" />,
  },
];

const authProtectedVisitanteRoutes = [
  { path: "/lancamentos", component: Lancamentos },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/lancamentos" />,
  },
];

const authProtectedComercialRoutes = [
  { path: "/cad-usuarios", component: Usuarios, adminRoute: true },
  { path: "/cad-conexoes", component: ConexoesComercial, adminRoute: true },
  { path: "/cad-email", component: Email, adminRoute: true },
  { path: "/chats-comercial", component: ChatsComercial },
  { path: "/profile", component: Profile },
  { path: "/config", component: Config, adminRoute: true },
  { path: "/atendimentos", component: Atendimentos },
  { path: "/cad-categorias", component: Categoria },
  { path: "/disparos", component: DisparosComercial },
  { path: "/cad-mensagens", component: MensagemRapida, adminRoute: true },
  { path: "/agendamento-resposta", component: AgendamentoResposta },

  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/chats-comercial" />,
  },
];

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/", component: Login },
];

const routes = [
  ...authProtectedSuporteRoutes,
  ...publicRoutes,
  ...authProtectedVisitanteRoutes,
  ...authProtectedComercialRoutes,
];

export {
  publicRoutes,
  authProtectedSuporteRoutes,
  authProtectedVisitanteRoutes,
  authProtectedComercialRoutes,
  routes,
};
