import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//import routes
import {
  publicRoutes,
  authProtectedSuporteRoutes,
  authProtectedVisitanteRoutes,
  authProtectedComercialRoutes,
} from "./routes";

//import layouts
import NonAuthLayout from "../layouts/NonAuth";
import AuthLayout from "../layouts/AuthLayout/";

import jwtService from "src/services/jwt.service";
import { useLoadContext } from "src/contexts/LoadContext";
jwtService.init();

// handle auth and authorization
const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  user,
  adminRoute,
  ...rest
}) => {
  console.log(user)
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected && !user) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }

        if(adminRoute && !user.isAdmin) {
          return (
            <Redirect
              to={{ pathname: "/", state: { from: props.location } }}
            />
          );
        }

        // authorised so return component
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

/**
 * Main Route component
 */
const Routes = () => {
  const { user } = useLoadContext();
  const [authProtectedRoutes, setAuthProtectedRoutes] = useState();

  useEffect(() => {
    if (user) {
      if (user.setor && ["suporte", "dev", "financeiro"].includes(user.setor))
        setAuthProtectedRoutes(authProtectedSuporteRoutes);
      if (user.setor && ["comercial"].includes(user.setor))
        setAuthProtectedRoutes(authProtectedComercialRoutes);
      if (user.setor && ["visitante"].includes(user.setor))
        setAuthProtectedRoutes(authProtectedVisitanteRoutes);
    } else {
      setAuthProtectedRoutes(undefined)
    }
  }, [user]);

  return (
    // rendering the router with layout
    <BrowserRouter>
      <React.Fragment>
        <Suspense fallback={<div></div>}>
          <Switch>
            {/* public routes */}
            {!authProtectedRoutes && publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {/* ROTAS PROTECTEDS */}
            {authProtectedRoutes &&
              authProtectedRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={AuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  user={user}
                  adminRoute={route.adminRoute}
                />
              ))}
          </Switch>
        </Suspense>
        <ToastContainer />
      </React.Fragment>
    </BrowserRouter>
  );
};

export default Routes;
