import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import utilImages from 'src/utils/util-images';

function AvatarCustom(props) {
  let { chat, width, avatarSize, showStatus, isStaticImage = false } = props;
  const [avatar, setAvatar] = useState(chat.pfpUrl || null);
  useEffect(() => {
    const getImageAvatar = () => {
      if (!chat.pfpUrl && !isStaticImage) return;
      try {
        const image = utilImages.getImage(chat.pfpUrl);
        setAvatar(image);
      } catch (error) {
        console.log('Erro ao carregar a imagem:', error);
      }
    }

    getImageAvatar()
  }, [chat.pfpUrl, isStaticImage]);

  return (
    <div
      className={`${avatarSize ? avatarSize : "avatar-xs"} mx-auto d-block chat-user-img align-self-center me-3 ms-0 ${chat.userDestino ? chat.userDestino.status : ""
        } ${showStatus ? showStatus : ""
        }`}
    >
      {/*chat.isAtendimento ? (*/
        chat.pfpUrl !== undefined ? (
          <React.Fragment>
            {/* <span
              className="avatar-title rounded-circle bg-soft-primary text-primary"
              style={{ userSelect: "none" }}
            > */}
            { width ? (
              <Avatar alt="user-avatar" src={avatar || ''} style={{ width: width, height: width }} />
            ) : (
              <Avatar alt="user avatar" src={avatar || ''} />
            )}
            {/* </span> */}
            {
              showStatus && (
                <span className="user-status"></span>
              )
            }
          </React.Fragment>
        ) : (
          <React.Fragment>
            <span
              className="avatar-title rounded-circle bg-soft-primary text-primary"
              style={{ userSelect: "none" }}
            >
              {
                chat.userDestino
                  ? chat.userDestino.name.charAt(0).toUpperCase()
                  : chat && chat.name
                    ? chat.name.charAt(0).toUpperCase()
                    : ''
              }
            </span>
          </React.Fragment>
        )
      }
    </div>
  );
}

export default React.memo(AvatarCustom);
