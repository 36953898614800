
const endpoints = [
    {
        origin: 'localhost',
        endpoint: 'http://127.0.0.1:3100/'
    },
    {
        origin: '192.168.0.20',
        endpoint: 'http://192.168.0.20:3100/'
    },
    {
        origin: '192.168.0.151',
        endpoint: 'http://192.168.0.151:3100/'
    },
    {
        origin: '192.168.0.137',
        endpoint: 'http://192.168.0.137:3100/'
    },
    {
        origin: 'gzap-teste.gtech.site',
        endpoint: 'https://api-gzap-teste.gtech.site/'
    },
    {
        origin: 'gzap.gtech.site',
        endpoint: 'https://api-gzap.gtech.site/'
    }
]

const config = {
    versao_gzap: '4.1.0',

    API_URL: endpoints.find(e => (window.location.href).includes(e.origin)).endpoint,
}

export default config
