import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  UncontrolledTooltip,
} from "reactstrap";
import { useLoadContext } from "src/contexts/LoadContext";
import logo from "../../assets/images/logo.svg";
import config from "src/config";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import AlertDialogSlide from "src/components/DialogQuestion";
import conexaoService from "src/services/conexao/conexao.service";
import messageService from "src/services/message.service";
import PhonelinkEraseIcon from '@material-ui/icons/PhonelinkErase';
import IconButton from "@material-ui/core/IconButton";

function DropDownGzap({ id, isDropOpen, toggleDrop, dropClassName }) {
  const { user, handleSetor } = useLoadContext();

  const [value, setValue] = React.useState(user.setor);
  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
    handleSetor(event.target.value)
  };

  async function desconectarNumeros() {
    await conexaoService.destroyConectados().then(() => {
      messageService.successMessage(
        "Os números foram desconectados!"
      )
    }).finally(() =>
      setOpen(false)
    );
  }

  return (
    <Dropdown
      nav
      id={id}
      className={dropClassName}
      isOpen={isDropOpen}
      toggle={toggleDrop}
    >
      <DropdownToggle className="nav-link" tag="a">
        <div className="logo logo-light" id="logogzap">
          <span className="logo-sm">
            <img src={logo} alt="logo" height="30" />
          </span>
        </div>
        <UncontrolledTooltip target="logogzap" placement="top">
          {`Versão ${config.versao_gzap}`}
        </UncontrolledTooltip>
      </DropdownToggle>
      {
        user.isMaster &&
        <>
          <AlertDialogSlide
            open={open}
            setOpen={setOpen}
            handleSuccess={desconectarNumeros}
            title={`Deseja desconectar todos os números do ${user.setor}?`} />
          <DropdownMenu>
            <FormControl component="fieldset" style={{ padding: 10 }}>
              <RadioGroup value={value} onChange={handleChange}>
                <FormControlLabel value="suporte" control={<Radio style={value === 'suporte' ? { color: '#7269ef' } : {}} />} label="Suporte" />
                <FormControlLabel value="comercial" control={<Radio style={value === 'comercial' ? { color: '#7269ef' } : {}} />} label="Comercial" />
                <FormControlLabel value="financeiro" control={<Radio style={value === 'financeiro' ? { color: '#7269ef' } : {}} />} label="Financeiro" />
              </RadioGroup>
              <div onClick={() => setOpen(true)}>
                <UncontrolledTooltip target="desconectarMaster" placement="bottom">
                  Desconectar Números
                </UncontrolledTooltip>
                <IconButton
                  id="desconectarMaster"
                  color="primary"
                  component="span"
                  style={{ left: 0, top: 0, marginLeft: -20, float: "center" }}>
                  <PhonelinkEraseIcon style={{ fontSize: 30 }} />
                </IconButton>
                <span style={{ fontSize: 16 }}>Desconectar</span>
              </div>
            </FormControl>
          </DropdownMenu>
        </>
      }
    </Dropdown>
  );
}

export default React.memo(DropDownGzap);
