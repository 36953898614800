import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
// import { getFirebaseBackend } from "../../helpers/firebase";
import jwtService from "src/services/jwt.service";
import messageService from "src/services/message.service";

import {
    LOGIN_USER,
    LOGOUT_USER,
    // REGISTER_USER,
    // FORGET_PASSWORD
} from './constants';

import {
    loginUserSuccess,
    // forgetPasswordSuccess,
    apiError
} from './actions';


//Initilize firebase
// const fireBaseBackend = getFirebaseBackend();

/**
 * Login the user
 * @param {*} payload - username and password 
 */
function* login({ payload: { username, password, history } }) {
    try {
        jwtService.signInWithUsernameAndPassword(username, password)
            .then((user) => {
                localStorage.setItem("authUser", JSON.stringify(user));
                loginUserSuccess(user);

                history.push('/');
            })
            .catch(error => {
                console.log('error');
                console.log(error);
                messageService.errorMessage(
                    "Email e/ou senha inválido(s)"
                );
            });
    } catch (error) {
        yield put(apiError(error));
    }
}


/**
 * Logout the user
 * @param {*} param0 
 */
function* logout({ payload: { history } }) {
    try {
        // jwtService.logout();
        localStorage.removeItem("authUser");
        yield call(() => {
            history.push("/login");
        });
    } catch (error) { }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

function* authSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
    ]);
}

export default authSaga;