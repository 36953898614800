import io from "socket.io-client";
import { EventEmitter } from 'events';
import config from "src/config";

const eventEmitter = new EventEmitter();
class socketService {
  socket = undefined;

  init = async (user) => {
    if (this.socket) {
      this.socket.disconnect();
    }

    this.socket = io(config.API_URL, { query: { userId: user.id } });
    this.socket.on('connect', function () {
      // console.log('socket conectado com sucesso!');
    });

    this.socket.on('disconnect', function () {
      // console.log('socket disconnect com sucesso!')
    })

    this.socket.on('update_online_users', (response) => {
      eventEmitter.emit('update_online_users', response.data);
    })

    //GENERICOS
    this.socket.on('update_conexao', (response) => {
      eventEmitter.emit('update_conexao', response.data);
    })

    //NOVOS
    this.socket.on('update_meus_chats_list', (response) => {
      eventEmitter.emit('update_meus_chats_list', response);
    })

    this.socket.on('update_fila_espera_list', (response) => {
      eventEmitter.emit('update_fila_espera_list', response);
    })

    this.socket.on('update_todos_chats_list', (response) => {
      eventEmitter.emit('update_todos_chats_list', response);
    })

    this.socket.on('update_agendamentos_list', (response) => {
      console.log('update_agendamentos_list', response)
      eventEmitter.emit('update_agendamentos_list', response);
    })

    this.socket.on('update_message_sended', (response) => {
      eventEmitter.emit('update_message_sended', response);
    })

    //COMERCIAL
    this.socket.on('update_comercial_em_contato', (response) => {
      eventEmitter.emit('update_comercial_em_contato', response);
    })
    
    this.socket.on('update_online_users_comercial', (response) => {
      eventEmitter.emit('update_online_users_comercial', response);
    })

    this.socket.on('update_comercial_em_espera', (response) => {
      eventEmitter.emit('update_comercial_em_espera', response);
    })

    this.socket.on('update_comercial_negociando', (response) => {
      eventEmitter.emit('update_comercial_negociando', response);
    })

    this.socket.on('update_comercial_inativa', (response) => {
      eventEmitter.emit('update_comercial_inativa', response);
    })

    this.socket.on('comercial_update_message', (response) => {
      eventEmitter.emit('comercial_update_message', response);
    })

    this.socket.on('update_comercial_fechado', (response) => {
      eventEmitter.emit('update_comercial_fechado', response);
    })

    await this.socket.connect();
  }

  getSocket = () => {
    return this.socket;
  };

  getEventEmitter = () => {
    return eventEmitter;
  };

}

const instance = new socketService();

export default instance;
