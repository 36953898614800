import BaseResourceService from "src/services/BaseResourceService";
import moment from "moment";
class AgendamentoRespostaService extends BaseResourceService {
    endpoint = "agendamento";

    getAllAgendamentoResposta = async (startDate, endDate, numero) => {
      let params = [];

      if (numero){
        params.push({chave: 'numero', valor: numero});
      } else {
        if (startDate)
          params.push({chave: 'startDate', valor: moment(startDate).format("YYYY-MM-DD HH:mm")});
      
        if (endDate)
          params.push({chave: 'endDate', valor: moment(endDate).format("YYYY-MM-DD HH:mm")});
      }

      return this.getAll(this.endpoint, params);
    };

    getAgendamentoResposta = async (numero) => {
        let params = [];
        // params.push({ chave: 'isAtivo', valor: isAtivo });
        params.push({chave: 'numero', valor: numero})
        return this.getAll(this.endpoint, params);
    };

    remove = async (id) => {
        return this.delete(`${this.endpoint}/delete/${id}`);
    };

    update = async (params) => {
        return new Promise((resolve, reject) => {
            this.post(`/${this.endpoint}/update`, params)
              .then((response) => {
                if (response && response.data) {
                  resolve(response.data);
                } else {
                  this.tratarErrosToast(response);
                  reject(response.data.error);
                }
              })
              .catch((error) => {
                this.tratarErrosToast(error.response);
                reject(error.response);
              });
          });
    };

    salvarAgendamentoResposta(MensagemRapidas) {
        return new Promise((resolve, reject) => {
            this.saveOrUpdate(`/${this.endpoint}`, MensagemRapidas)
                .then((response) => {
                    if (response && response.data) {
                        resolve(response.data);
                    } else {
                        this.tratarErrosToast(response);
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    this.tratarErrosToast(error.response);
                    reject(error.response);
                });
        });
    }

    getByCodigo = async (id) => {
        return this.findModelo(this.endpoint, id);
    };

}

const service = new AgendamentoRespostaService();
export default service;
