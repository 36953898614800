import BaseResourceService from "src/services/BaseResourceService";

class MensagemService extends BaseResourceService {
  endpoint = "mensagem";

  enviarMsg = async (conteudo, chatId, userDestino, replyMsgId, removeSign, msgInterna, userId) => {
    return new Promise((resolve, reject) => {
      this.saveOrUpdate(`/${this.endpoint}/enviar`, { conteudo, chatId, userDestino, replyMsgId, removeSign: userId ? true : removeSign, isMsgInternaOculta: msgInterna, userId })
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  };

  enviarMsgAtendimendo = async (conteudo, chatId, conversaId, replyMsgId, removeSign, userId) => {
    console.log(removeSign, userId)
    return new Promise((resolve, reject) => {
      this.saveOrUpdate(`/${this.endpoint}/enviar_atendimento`, { conteudo, chatId, conversaId, replyMsgId, removeSign: userId ? true : removeSign, userId })
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  };

  enviarAudio = async (audioBase64, chatId, userDestino, msgInterna, userId, replyMsgId) => {
    return new Promise((resolve, reject) => {
      console.log(replyMsgId)
      this.saveOrUpdate(`/${this.endpoint}/enviar_audio`, { audioBase64, chatId, userDestino, msgInterna, userId, replyMsgId })
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  };

  enviarAudioAtendimendo = async (audioBase64, chatId, conversaId, msgInterna, userId, replyMsgId) => {
    return new Promise((resolve, reject) => {
      this.saveOrUpdate(`/${this.endpoint}/enviar_atendimento_audio`, { audioBase64, chatId, conversaId, msgInterna, userId, replyMsgId })
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  };

  enviarImagem = async (imagemBase64, chatId, userDestino, mimetype, msgInterna, userId, replyMsgId) => {
    return new Promise((resolve, reject) => {
      this.saveOrUpdate(`/${this.endpoint}/enviar_imagem`, { imagemBase64, chatId, userDestino, mimetype, msgInterna, userId, replyMsgId })
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  };

  enviarImagemAtendimento = async (imagemBase64, chatId, conversaId, mimetype, msgInterna, userId, replyMsgId, text) => {
    return new Promise((resolve, reject) => {
      this.saveOrUpdate(`/${this.endpoint}/enviar_atendimento_imagem`, { imagemBase64, chatId, conversaId, mimetype, msgInterna, userId, replyMsgId, text })
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  };

  enviarArquivo = async (arquivoBase64, chatId, userDestino, mimetype, fileName, msgInterna, userId, replyMsgId) => {
    return new Promise((resolve, reject) => {
      this.saveOrUpdate(`/${this.endpoint}/enviar_arquivo`, { arquivoBase64, chatId, userDestino, mimetype, fileName, msgInterna, userId, replyMsgId })
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  };

  enviarArquivoAtendimento = async (arquivoBase64, chatId, conversaId, mimetype, fileName, msgInterna, userId, replyMsgId) => {
    return new Promise((resolve, reject) => {
      this.saveOrUpdate(`/${this.endpoint}/enviar_atendimento_arquivo`, { arquivoBase64, chatId, conversaId, mimetype, fileName, msgInterna, userId, replyMsgId })
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  };

  resendMessage = async (msgId, conversaId) => {
    return new Promise((resolve, reject) => {
      this.saveOrUpdate(`/${this.endpoint}/resend_message_atendimento`, { msgId, conversaId })
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  };

  deleteMessage = async (msgId, conversaId) => {
    return new Promise((resolve, reject) => {
      this.saveOrUpdate(`/${this.endpoint}/delete_message_atendimento`, { msgId, conversaId })
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  };

  getMediaById = async (media) => {
    let params = [];
    params.push({ chave: 'mediaId', valor: media });
    return this.getAll(`${this.endpoint}/getMediaById`, params)
  };

  getAllMensagens = async (chatId) => {
    let params = [];
    params.push({ chave: 'chatId', valor: chatId });
    return this.getAll(this.endpoint, params);
  };

  getNextMsg = async (chatId, lastMsg) => {
    let params = [];
    params.push({ chave: 'chatId', valor: chatId });
    params.push({ chave: 'lastMsg', valor: lastMsg });
    return this.getAll(`${this.endpoint}/nextmsg`, params);
  };

  downloadFile = async (msgId) => {
    let params = [];
    params.push({ chave: 'msgId', valor: msgId });
    return this.getAll(`${this.endpoint}/download_file`, params);
  };

}

const sevice = new MensagemService();
export default sevice;
