import { useEffect, useState } from "react";
import ChatsItem from "../Chat/ChatsItem";
import SimpleBar from "simplebar-react";

export default function SchedulesServices({ agendamentos, openNesteModal, setNestedModal, setSelectedSchedule }) {
  const [agendamentosDistinct, setAgendamentosDistinct] = useState(agendamentos);
  const handleSelectSchedule = (chat) => {
    setSelectedSchedule(chat)
    setNestedModal(!openNesteModal);
  }

  useEffect(() => {
    const uniqueNumeroClient = [...new Set(agendamentos.map(item => item.numeroClient))];
  
    const separacaoNumeroClient = uniqueNumeroClient.map(numeroClient => {
      return agendamentos.find(item => item.numeroClient === numeroClient);
    });

    setAgendamentosDistinct(separacaoNumeroClient);
  }, [agendamentos]);

  return (
    <div className="sidebarSchedules">
      <h5 className="mt-3 mb-4 w-full d-flex justify-content-center">
        {`Agendamentos (${ (agendamentosDistinct.length) || 0 })`}
      </h5>
      <ul
        className="list-unstyled chat-list chat-user-list"
        id="chat-list"
      >
        <SimpleBar
          style={{ maxHeight: "100%",  paddingTop: 0 }}
          className="view-pages"
        >
          {agendamentosDistinct.map((agendamento, index) => (
            <ChatsItem
              key={index}
              chat={agendamento.conversa}
              widthText={250}
              agendamentoResposta={agendamento}
              openAllSchedulesForNumber={handleSelectSchedule}
            />
          ))}
        </SimpleBar>
      </ul>
    </div>
  )
}