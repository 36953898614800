import axios from "axios";
import socketService from "src/services/socket.service";
import config from "src/config";

const API_CONFIG = config.API_URL;
const PATH_LOGIN = "/usuario/login";
const PATH_LOGOUT = "/usuario/logout";
const AUTH_USER = "authUser";
let socket = undefined;

const customAxios = axios.create({
  baseURL: API_CONFIG,
});

class jwtService {
  init = async () => {
    this.setInterceptors();
    let user = await this.getAuthUser();
    if (user) {
      socketService.init(user);
    }
  };

  login = async (username, password) => {
    var reqData = `username=${username}&password=${password}`;
    return axios.request({
      url: PATH_LOGIN,
      method: "post",
      baseURL: API_CONFIG,
      data: reqData,
    });
  };

  logout = async () => {
    return new Promise(async (resolve, reject) => {
      let response = await this.getInterceptor().post(PATH_LOGOUT, { logout: true });
      localStorage.removeItem(AUTH_USER);
      resolve(response);
    });
  };

  signInWithUsernameAndPassword = async (username, password) => {
    return new Promise((resolve, reject) => {
      this.login(username, password)
        .then((response) => {
          if (response.data) {
            socketService.init(response.data);
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  setAuthUser = (data) => {
    localStorage.setItem(AUTH_USER, JSON.stringify(data));
  };

  getAuthUser = () => {
    return JSON.parse(localStorage.getItem(AUTH_USER));
  };

  getSocket = () => {
    return socket;
  };

  setInterceptors = () => {
    customAxios.interceptors.request.use(
      async (config) => {
        const authUser = await this.getAuthUser();
        config.headers.Authorization = `Bearer ${authUser.token}`;
        config.headers.userId = authUser.id;
        config.headers.setor = authUser.setor;
        return config;
      },
      (error) => {
        console.log(error);
      }
    );

    customAxios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.response.status === 403) {
          console.log('403 errorrr!!! deslogando');
          localStorage.removeItem(AUTH_USER);
          window.location.reload(false);
        }
        throw error;
      }
    );

    // TODO
    // customAxios.interceptors.response.use(
    //   (response) => {
    //     return response;
    //   },
    //   async (error) => {
    //     const originalRequest = error.config;
    //     if (error.response.status === 401){
    //       if(!originalRequest.__isRetryRequest) {
    //         return this.getAuthToken()
    //           .then((response) => {
    //             this.setTokens(response);
    //             error.config.__isRetryRequest = true;
    //             return customAxios(originalRequest);
    //           })
    //           .catch((err) => {
    //             this.setSession(null);
    //             this.setTokens(null);
    //             window.location.reload();
    //             throw err;
    //           });
    //         }else{
    //           this.setSession(null);
    //           this.setTokens(null);
    //           window.location.reload();
    //         }
    //     }
    //     throw error;
    //   }
    // );
  };

  updateInterceptor() {
    customAxios.interceptors.request.use(
      async (config) => {
        const authUser = await this.getAuthUser();
        config.headers.Authorization = `Bearer ${authUser.token}`;
        config.headers.userId = authUser.id;
        config.headers.setor = authUser.setor;
        return config;
      },
      (error) => {
        console.log(error);
      }
    );
    return customAxios;
  }
  getInterceptor() {
    // customAxios.interceptors.request.use(
    //   async (config) => {
    //     const authUser = await this.getAuthUser();
    //     config.headers.Authorization = `Bearer ${authUser.token}`;
    //     config.headers.userId = authUser.id;
    //     config.headers.setor = authUser.setor;
    //     return config;
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );
    return customAxios;
  }
}

const instance = new jwtService();

export default instance;
