import moment from "moment";
import "./styles.css";
import { DynamicYear } from "./DynamicYear";
import 'moment/dist/locale/pt-br'
export default function SidebarCalendar({currentDate, setCurrentDate}){
  const allMonths = moment.months();

  const changeMonth = (monthNumber) => {
    setCurrentDate({
      startDate: moment(currentDate.startDate).set('month', monthNumber),
      endDate: moment(currentDate.endDate).set('month', monthNumber)
    });
  }

  return (
    <div className="contentMonth">
      <div className="">
        <DynamicYear currentDate={currentDate} setCurrentDate={setCurrentDate} />
        {
          allMonths.map((item, index) => (
            <h5 key={index} onClick={() => changeMonth(index)} className="monthItem" >{item.toUpperCase()}</h5>
          ))
        } 
      </div>
    </div>
  )
}