import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button
} from "reactstrap"
import SidebarMonth from "../../../components/Calendar/SidebarCalendar"
import CalendarFull from "../../../components/Calendar/CalendarFull"
import SchedulesServices from "../../../components/Calendar/SchedulesServices"
import moment from "moment";
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css';
import agendamentoRespostaService from "../../../services/AgendamentoResposta/agendamentoResposta.service";
import CustomInput from "src/components/Inputs/CustomInput";
import messageService from "src/services/message.service";

export default function ModalCentralAgendamento({ openModal, setOpenModal, openNesteModal, setNestedModal }) {
  const [currentDate, setCurrentDate] = useState({
    startDate: moment(),
    endDate: moment()
  });

  const [agendamentos, setAgendamentos] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const [schedulesByNumber, setSchedulesByNumber] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedFields, setEditedFields] = useState({
    id: "",
    mensagem: "",
    tipo: "",
    horarioAgendamento: "",
  });

  useEffect(() => {
    const getAllAgendamentos = async () => {
      await agendamentoRespostaService.getAllAgendamentoResposta(currentDate.startDate, currentDate.endDate, null).then(data => {
        setAgendamentos(data);
      })
    };

    getAllAgendamentos();
  }, [openModal, currentDate]);

  useEffect(() => {
    const getAgendamentosByNumber = async () => {
      await agendamentoRespostaService.getAllAgendamentoResposta(null, null, selectedSchedule.numeroClient).then(data => {
        setSchedulesByNumber(data);
      })
    };

    getAgendamentosByNumber();
  }, [openNesteModal, selectedSchedule]);

  const toggle = () => setOpenModal(!openModal);
  const toggleNested = () => {
    setEditedFields({});
    setNestedModal(!openNesteModal);
  };


  const handleEditAgendamento = (agendamento) => {
    setIsEditing(!isEditing);
    setEditedFields({
      id: agendamento._id,
      mensagem: agendamento.mensagem,
      tipo: agendamento.tipo,
      horarioAgendamento: agendamento.horarioAgendamento
    });
  }

  const handleDestroyAgendamento = async (agendamentoToDelete) => {
    setIsEditing(!isEditing);
    await agendamentoRespostaService.remove(agendamentoToDelete._id).then((e) => {

      let newListSchedules = [];

      schedulesByNumber.map((agendamento) => {
        if (agendamento._id !== agendamentoToDelete._id)
          newListSchedules.push(agendamento);
      });

      setSchedulesByNumber(newListSchedules);
      messageService.successMessage(`Agendamento ${moment(agendamentoToDelete.horarioAgendamento).format("DD-MM-YYYY HH:mm")} foi deletado`)
    });
  }

  const reorderSchedules = (agendamentoAtualizado) => {
    let result = schedulesByNumber.map((agendamentoAntigo) => {
      return agendamentoAntigo._id === agendamentoAtualizado._id ? agendamentoAtualizado : agendamentoAntigo
    });
    return result;
  }

  const handleSaveAgendamento = async (agendamentoModified) => {
    setIsEditing(!isEditing);
    setEditedFields({});
    await agendamentoRespostaService.update(agendamentoModified).then((agendamentoAtualizado) => {
      setSchedulesByNumber(() => reorderSchedules(agendamentoAtualizado));
    });
  }

  return (
    <>
      <Modal
        isOpen={openModal}
        centered
        toggle={toggle}
        size="xl"
        style={{
          marginTop: '0px',
          marginBottom: '0px'
        }}
      >
        <ModalBody
          tag="h5"
          className="font-size-16 d-flex"
          toggle={toggle}
          style={{padding: "0px", margin: "0px"}}
        >
          <Modal
            isOpen={openNesteModal}
            centered
            toggle={toggleNested}
            size="lg"
          >
            <ModalHeader
              toggle={() => setNestedModal(false)}
            >
              Todos Agendamentos | {selectedSchedule.numeroClient}
            </ModalHeader>
            <ModalBody
              tag="h5"
              className="font-size-16 d-flex"
              toggle={toggleNested}
              style={{padding: "10px", margin: "0px"}}
            >
              <div style={{overflow: 'auto', height: '450px'}}>
                <table className="table">
                  <thead className="text-primary">
                    <tr>
                      <th scope="col">
                        Título
                      </th>
                      <th scope="col">
                        Tipo
                      </th>
                      <th scope="col">
                        Data
                      </th>
                      <th scope="col">
                        Hora
                      </th>
                      <th scope="col">
                        Ações
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      schedulesByNumber.map((agendamento, index) => (
                        <tr key={index}>
                          <td>
                            <CustomInput
                              value={
                                agendamento._id === editedFields.id && isEditing
                                ? editedFields.mensagem
                                : agendamento.mensagem
                              }
                              type={'text'}
                              disabled={!(agendamento._id === editedFields.id && isEditing)}
                              onChange={(e) => 
                                setEditedFields({ ...editedFields, mensagem: e.target.value})}
                            />
                          </td>
                          <td>
                            <CustomInput
                              value={
                                agendamento._id === editedFields.id && isEditing
                                ? editedFields.tipo
                                : agendamento.tipo
                              }
                              type={'text'}
                              disabled={true}
                              onChange={(e) => setEditedFields({ ...editedFields, tipo: e.target.value})}
                            />
                          </td>
                          <td>
                            <CustomInput
                              value={
                                agendamento._id === editedFields.id && isEditing
                                ? moment(editedFields.horarioAgendamento).format("YYYY-MM-DD") 
                                : moment(agendamento.horarioAgendamento).format("YYYY-MM-DD")
                              }
                              type={'date'}
                              disabled={!(agendamento._id === editedFields.id)}
                              onChange={(e) => {
                                const [year, month, date] = e.target.value.split("-");
                                setEditedFields({
                                  ...editedFields,
                                  horarioAgendamento: 
                                    moment(editedFields.horarioAgendamento)
                                      .set('year', year)
                                      .set('month', month)
                                      .set('date', date)
                                })
                              }}
                            />
                          </td>
                          <td>
                            <CustomInput
                              value={
                                agendamento._id === editedFields.id && isEditing
                                ? moment(editedFields.horarioAgendamento).format("HH:mm")
                                : moment(agendamento.horarioAgendamento).format("HH:mm")
                              }
                              type={'time'}
                              disabled={!(agendamento._id === editedFields.id)}
                              onChange={(e) => {
                                const [hours, minutes] = e.target.value.split(":");
                                setEditedFields({
                                  ...editedFields,
                                  horarioAgendamento:
                                    moment(editedFields.horarioAgendamento)
                                      .set('minute', minutes)
                                      .set('hour', hours)
                                });
                              }}
                            />
                          </td>
                          <td>
                            <div className="d-flex" style={{gap: 2}}>
                              {!(agendamento._id === editedFields.id) ? (
                                <Button
                                  color="link"
                                  className="text-decoration-none bg-light pr-1"
                                  type="button"
                                  name="Editar Agendamento"
                                  onClick={() => handleEditAgendamento(agendamento)}
                                > 
                                  <i className="ri-edit-2-fill font-size-18"></i>
                                </Button>
                              ) : (
                                <Button
                                  color="link"
                                  className="text-decoration-none bg-light pr-1"
                                  type="button"
                                  name="Salvar Agendamento"
                                  onClick={() => handleSaveAgendamento(editedFields)}
                                >
                                  <i className="ri-save-2-line font-size-18"></i>
                                </Button>
                              )}
                              <Button
                                color="link"
                                className="text-decoration-none bg-light pr-1"
                                type="button"
                                onClick={() => handleDestroyAgendamento(agendamento)}
                              >
                                <i className="ri-chat-delete-fill font-size-18"></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </ModalBody>
          </Modal>
          <SidebarMonth currentDate={currentDate} setCurrentDate={setCurrentDate}/>
          <CalendarFull currentDate={currentDate} setCurrentDate={setCurrentDate}/>
          <SchedulesServices
            agendamentos={agendamentos} 
            openNesteModal={openNesteModal}
            setNestedModal={setNestedModal}
            selectedSchedule={selectedSchedule}
            setSelectedSchedule={setSelectedSchedule}
          />
        </ModalBody>
      </Modal>
    </>
  );
}