import BaseResourceService from "src/services/BaseResourceService";

class UsuarioService extends BaseResourceService {
  endpoint = "usuario";

  getAllUsuarios = async (isAtivo = true) => {
    let params = [];
    params.push({ chave: 'isAtivo', valor: isAtivo });
    return this.getAll(this.endpoint, params);
  };

  getAllUsuariosBySetor = async (setor, isAtivo = true) => {
    let params = [];
    params.push({ chave: 'isAtivo', valor: isAtivo });
    params.push({ chave: 'setor', valor: setor });
    return this.getAll(this.endpoint, params);
  };

  getAllUsuariosOnline = async () => {
    let params = [];
    return this.getAll(this.endpoint + "/online", params);
  };

  salvarUsuario(usuario) {
    return new Promise((resolve, reject) => {
      this.saveOrUpdate(`/${this.endpoint}`, usuario)
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  }

  updateProfile(formData) {
    return new Promise((resolve, reject) => {
      this.post(`/${this.endpoint}/profile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }},
      )
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  }

  inspectUser(params) {
    return new Promise((resolve, reject) => {
      this.post(`/${this.endpoint}/inspectUser`, params)
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  }

  getByCodigo = async (id) => {
    return this.findModelo(this.endpoint, id);
  };

}

const sevice = new UsuarioService();
export default sevice;
