import jwtService from "./jwt.service.js";
import { Component } from "react";
import messageService from "src/services/message.service";
import WarningToast from "src/components/Toasts/WarningToast";
import { toast } from "react-toastify";

export default class BaseResourceService extends Component {
  find = async (url) => {
    let response = await jwtService.getInterceptor().get(url);
    return response;
  };

  get = async (endpoint, config = undefined) => {
    let response = undefined;
    if (config) {
      response = await jwtService.getInterceptor().get(endpoint, config);
    } else {
      response = await jwtService.getInterceptor().get(endpoint);
    }
    return response;
  };

  delete = async (endpoint, config = undefined) => {
    let response = undefined;
    response = await jwtService.getInterceptor().delete(endpoint);
    return response;
  };

  // delete = async (url) => {
  //   let response = await jwtService.getInterceptor().delete(url);
  //   return response;
  // };

  saveOrUpdate = async (url, data) => {
    let response;
    if (data.id)
      response = await jwtService
        .getInterceptor()
        .put(url + `/${data.id}`, data);
    else if (data._id)
      response = await jwtService
        .getInterceptor()
        .put(url + `/${data._id}`, data);
    else response = await jwtService.getInterceptor().post(url, data);
    return response;
  };

  saveOrUpdateFormData = async (url, data, codigo) => {
    let response;
    if (codigo)
      response = await jwtService
        .getInterceptor()
        .put(url + `/${codigo}`, data);
    else response = await jwtService.getInterceptor().post(url, data);
    return response;
  };

  post = async (url, data) => {
    let response = await jwtService.getInterceptor().post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  };

  // update = async (url, data) => {
  //   let response = await jwtService.getInterceptor().put(url, data, {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   });
  //   return response;
  // };

  getAll = (endpoint, params) => {
    let url = this.addParamsToUrl(`/${endpoint}`, params);
    return new Promise((resolve, reject) => {
      this.get(url)
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  };

  salvarModeloFormData = (endpoint, modelo, codigo) => {
    return new Promise((resolve, reject) => {
      this.saveOrUpdateFormData(`/${endpoint}`, modelo, codigo)
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  };

  // findModelo = (endpoint, codigo) => {
  //   return new Promise((resolve, reject) => {
  //     this.find(`/${endpoint}/${codigo}`)
  //       .then((response) => {
  //         if (response && response.data) {
  //           resolve(response.data);
  //         } else {
  //           reject(response.data.error);
  //         }
  //       })
  //       .catch((error) => {
  //         reject(error.response);
  //       });
  //   });
  // };

  addParamsToUrl(url, params) {
    if (params != null) {
      params.forEach((param, index) => {
        if (index === 0) url = url + "?";
        url = url + `${param["chave"]}=${param["valor"]}`;
        if (index + 1 !== params.length) url = url + "&";
      });
    }
    return url;
  }

  tratarErrosToast(error, toastId) {
    console.log('error')
    if (!toastId) {
      toastId = messageService.successMessage(<WarningToast texto={""} />, {
        toastId: "errorId",
        position: "top-center",
        autoClose: false,
      });
    }
    if (error && error.data) {
      if (error.data.keyValue)
        messageService.updateToast(toastId, {
          render: (
            <WarningToast
              texto={`Já existe um cadastro com ${JSON.stringify(
                error.data.keyValue
              )
                .replace(/["{}]/g, "")
                .replace(":", ": ")}`}
            />
          ),
          position: "bottom-right",
          autoClose: true,
          type: toast.TYPE.ERROR,
        });
      else
        messageService.updateToast(toastId, {
          render: <WarningToast texto={error.data.error} />,
          position: "bottom-right",
          autoClose: true,
          type: toast.TYPE.ERROR,
        });

      console.log("=== MENSAGEM DE ERRO ===");
      console.error(
        error.data.errors ? error.data.errors[0].message : error.data
      );
    }
  }
}
