
export const getValue = (p1, p2, value, space) => {
    let pos1, pos2;
    pos1 = value.indexOf(p1);
    pos2 = value.indexOf(p2);

    return value.substring(pos1 + space, pos2);
}

export const getValueEnd = (p1, value, space) => {
    let pos1;
    pos1 = value.indexOf(p1);

    return value.substring(pos1 + space, (value).trim().length);
}